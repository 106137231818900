<template>
  <v-card>
    <v-card-title class="align-start">
      <span>{{title}}</span>
    </v-card-title>

    <v-card-text>
        <v-data-table
          :items-per-page="itemsPerPage"
          :headers="headers"
          :items="quiz"
          hide-default-footer
          :search="search"
        >
            <template v-slot:[`item.grade`]="{ item }">
                <v-chip
                    :color="getColor(item.grade)"
                >
                    {{ item.grade }} 
                </v-chip>
            </template>
        </v-data-table>

        <!-- Table -->
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  components: {
  },
  mounted() {
    
  },
  computed:{
    quiz(){
      return this.$store.getters.getQuizStat
    },
    title(){
        return 'My Quiz Performance Overview'
    },
    staff(){
      return this.$store.getters.isStaff
    },
  },
  data() {

    return {
        search: '',
        itemsPerPage: 20,
        headers: [
        {
          text: 'Quiz',
          align: 'start',
          sortable: false,
          value: 'quiz_quizName',
          width: 120 
        },
        { text: 'Grade', value: 'grade', width: 100, align: 'start', },
        { text: 'Narration', value: 'percentile' }
      ],
    }
  },
  methods: {
        getColor (grade) {
            if (grade === 'A') return 'success'
            else if (grade === 'B') return 'success'
            else if (grade === 'C') return 'warning'
            else return 'error'
        },
  }
}
</script>
