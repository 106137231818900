<template>
  <v-card>
    <v-card-title class="align-start">
      <span>{{title}}</span>
    </v-card-title>

    <v-card-text>
        <v-data-table
          :items-per-page="itemsPerPage"
          :headers="headers"
          :items="mooc"
          hide-default-footer
          :search="search"
        >
        </v-data-table>
        <!-- Table -->
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  components: {
  },
  mounted() {
    
  },
  computed:{
    mooc(){
      return this.$store.getters.getMoocStat
    },
    title(){
        return 'My Mooc Performance Overview'
    },
  },
  data() {

    return {
        search: '',
        itemsPerPage: 20,
        headers: [
        {
          text: 'Mooc',
          align: 'start',
          sortable: false,
          value: 'mooc_moduleName',
          width: 450 
        },
        { text: 'Status', value: 'mooc_status', width: 100, align: 'start', },
        { text: 'Narration', value: 'narration' }
      ],
    }
  },
  methods: {
  }
}
</script>
