<template>
  <v-card>
    <v-card-title class="align-start">
      <span>{{title}}</span>
      <v-spacer></v-spacer>

      <v-switch
        class="mr-3"
        v-if="staff"
        @change="redoGraphMin"
        v-model="showMinMarks"
        inset
        :label="`Show Min Marks`"
      >
      </v-switch>
      <v-switch
        class="mr-3"
        v-if="staff"
        @change="redoGraphMax"
        v-model="showMaxMarks"
        inset
        :label="`Show Max Marks`"
      >
      </v-switch>

      <v-switch
        v-if="staff"
        @change="redoGraph"
        v-model="genderBreakdown"
        inset
        :label="`Show Performance By Gender`"
      >
      </v-switch>
    </v-card-title>

    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        :options="chartOptions"
        :series="chartData"
        height="400"
      >
      </vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'

export default {
  components: {
    VueApexCharts,
  },
  mounted() {
    this.plotGraph(this.$store.getters.getQuizStat)
  },
  computed:{
    quiz(){
      return this.$store.getters.getQuizStat
    },
    title(){
      if(!this.staff)
        return 'My Quiz Performance Overview'
      return this.genderBreakdown ? 'Quiz Performance Overview by Gender' : 'Quiz Performance Overview'
    },
    staff(){
      return this.$store.getters.isStaff
    },
  },
  watch: {
    quiz(n, o){
      this.plotGraph(n)
    }
  },
  data() {

    return {
      showMaxMarks: false,
      showMinMarks: false,
      genderBreakdown: false,
      chartOptions : {
        chart: {
          type: 'bar',
          offsetX: -15,
        },
        dataLabels: {
          enabled: false
        },
        colors: [this.$vuetify.theme.currentTheme.primary, this.$vuetify.theme.currentTheme.accent],
        stroke: {
          width: [4, 4, 4]
        },
        plotOptions: {
          bar: {
            columnWidth: "20%",
            borderRadius: 8,
            startingShape: 'rounded',
            endingShape: 'rounded',
            dataLabels: {
              position: "center" // top, center, bottom
            }
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          title: {
            text: 'Quiz Marks',
          },
          min: 0,
          tickAmount: 5,
          labels: {
            offsetY: 3,
            formatter: value => `${Math.round(value,2)}`,
          },
        },
        tooltip: {
          shared: false,
          intersect: true,
          x: {
            show: false
          }
        },
        dataLabels: {
          style: {
              fontSize: '10px',
              fontFamily: 'Arial, sans-serif'
          },
          background: {
            enabled: true
          },
          formatter: function (val) {
            return val;
          },
          enabled: true
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40
        },
        grid: {
          strokeDashArray: 12,
          padding: {
            right: 0,
          },
        },
      },
      chartData: [{data: []}],

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
  methods: {
    redoGraph(){
      this.plotGraph(this.$store.getters.getQuizStat)
    },
    redoGraphMax(){
      this.showMinMarks = false;
      this.plotGraph(this.$store.getters.getQuizStat)
    },
    redoGraphMin(){
      this.showMaxMarks = false;
      this.plotGraph(this.$store.getters.getQuizStat)
    },
    plotGraph(n){

      let categories = [];
      let dataValues = [];
      let dataValues2 = [];

      for(let i =0; i< n.length; i++){
        if(this.genderBreakdown){

          let femaleValue = this.showMaxMarks ? parseFloat(n[i].femaleMax) : 
              ( this.showMinMarks ? parseFloat(n[i].femaleMin) : parseFloat(n[i].femaleAverage) );
          let maleValue = this.showMaxMarks ? parseFloat(n[i].maleMax) : 
              ( this.showMinMarks ? parseFloat(n[i].maleMin) : parseFloat(n[i].maleAverage) );

          categories.push(n[i].quiz_quizName);
          dataValues.push(Math.round( maleValue, 0));
          dataValues2.push(Math.round( femaleValue, 0));
        }
        else{

          let value = this.showMaxMarks ? parseFloat(n[i].maxScore) : 
              ( this.showMinMarks ? parseFloat(n[i].minScore) : parseFloat(n[i].avgScore) );

          categories.push(n[i].quiz_quizName);
          dataValues.push(Math.round(value, 0));  
          dataValues2.push(Math.round(value, 0));  
        }
      }

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: categories,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tickPlacement: 'on',
            position: "top",
            labels: {
              show: true,
              style: {
                fontSize: '12px',
              },
            },
          },
        },
      };

      if(this.genderBreakdown){
        const params = [
          {
            name: 'Males',
            type: 'column',
            data: dataValues
          },
          {
            name: "Females",
            type: 'column',
            data: dataValues2
          },
        ]
        this.chartData = params
      }
      else{
          const params = [
          {
            name: 'Students',
            type: 'column',
            data: dataValues
          }
        ]
        this.chartData = params
      }

    }
  }
}
</script>
