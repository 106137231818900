<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <dashboard-statistics-card></dashboard-statistics-card>
    </v-col>

    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <dashboard-attendance></dashboard-attendance>
    </v-col>

    <v-col
      cols="12"
      sm="12"
      v-if='staff'
    >
      <dashboard-quiz></dashboard-quiz>
    </v-col>

    <v-col
      cols="12"
      sm="12"
      v-else
    >
      <dashboard-student-quiz></dashboard-student-quiz>
    </v-col>

    <v-col
      cols="12"
      sm="12"
      v-if='staff'
    >
      <dashboard-assignment></dashboard-assignment>
    </v-col>

    <v-col
      cols="12"
      sm="12"
      v-else
    >
      <dashboard-student-assignment></dashboard-student-assignment>
    </v-col>
    
    <v-col
      cols="12"
      sm="12"
      v-if='!staff'
    >
      <dashboard-student-mooc></dashboard-student-mooc>
    </v-col>

  </v-row>
</template>

<script>
  import DashboardStatisticsCard from './DashboardStatisticsCard.vue'
  import DashboardAssignment from './DashboardAssignment.vue'
  import DashboardAttendance from './DashboardAttendance.vue'
  import DashboardQuiz from './DashboardQuiz.vue'
  import DashboardStudentQuiz from './DashboardStudentQuiz.vue'
  import DashboardStudentAssignment from './DashboardStudentAssignment.vue'
  import DashboardStudentMooc from './DashboardStudentMooc.vue'


  export default {
    components: {
      DashboardStatisticsCard,
      DashboardQuiz,
      DashboardAssignment,
      DashboardAttendance,
      DashboardStudentQuiz,
      DashboardStudentAssignment,
      DashboardStudentMooc
    },
    computed: {
      staff(){
        return this.$store.getters.isStaff
      }
    }
  }
</script>
