<template>
  <v-card>
    <v-card-title class="align-start">
      <span>{{title}}</span>
      <v-spacer></v-spacer>
      <v-switch
        v-if="staff"
        @change="redoGraph"
        v-model="genderBreakdown"
        inset
        :label="`Show Attendence By Gender`"
      >
      </v-switch>
    </v-card-title>

    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        :options="options"
        :series="series"
        height="400"
      >
      </vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'

export default {
  components: {
    VueApexCharts,
  },
  mounted() {
    this.plotGraph(this.$store.getters.getAttendance)
  },
  computed:{
    attendance(){
      return this.$store.getters.getAttendance
    },
    title(){
      if(!this.staff)
        return 'My Attendance Overview'
      return this.genderBreakdown ? 'Attendance Overview by Gender' : 'Attendance Overview'
    },
    staff(){
      return this.$store.getters.isStaff
    },
  },
  watch: {
    attendance(n, o){
      this.plotGraph(n)
    }
  },
  data() {

    return {
      options : {
        chart: {
          type: 'bar',
          offsetX: -15,
        },
        dataLabels: {
          enabled: false
        },
        colors: [this.$vuetify.theme.currentTheme.primary, this.$vuetify.theme.currentTheme.accent],
        stroke: {
          width: [4, 4, 4]
        },
        plotOptions: {
          bar: {
            columnWidth: "20%",
            borderRadius: 8,
            startingShape: 'rounded',
            endingShape: 'rounded',
            dataLabels: {
              position: "center" // top, center, bottom
            }
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          title: {
            text: 'Attendance %',
          },
          min: 0,
          tickAmount: 5,
          labels: {
            offsetY: 3,
            formatter: value => `${Math.round(value,2)} %`,
          },
        },
        tooltip: {
          shared: false,
          intersect: true,
          x: {
            show: false
          }
        },
        dataLabels: {
          style: {
              fontSize: '10px',
              fontFamily: 'Arial, sans-serif'
          },
          background: {
            enabled: true
          },
          formatter: function (val) {
            return val + "%";
          },
          enabled: true
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40
        },
        grid: {
          strokeDashArray: 12,
          padding: {
            right: 0,
          },
        },
      },
      series: [],
      genderBreakdown: false,
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
  methods: {
    async redoGraph(){
      this.plotGraph(this.$store.getters.getAttendance) 
    },
    plotGraph(n){

      let categories = [];
      let dataValues = [];
      let dataValues2 = [];

      for(let i =0; i< n.length; i++){
        categories.push(n[i].subject_subjectName);
        if(this.genderBreakdown){
          dataValues.push(Math.round(parseFloat(n[i].maleAverage), 0));
          dataValues2.push(Math.round(parseFloat(n[i].femaleAverage), 0));
        }
        else{
          dataValues.push(Math.round(parseFloat(n[i].average), 0));
          dataValues2.push(Math.round(parseFloat(n[i].average), 0));
        }
      }

      this.options = {
        ...this.options,
        ...{
          xaxis: {
            categories: categories,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            tickPlacement: 'on',
            position: "top",
            labels: {
              show: true,
              style: {
                fontSize: '12px',
              },
            },
          },
        },
      };

      if(this.genderBreakdown){
        const params = [
          {
            name: 'Males',
            type: 'column',
            data: dataValues
          },
          {
            name: "Females",
            type: 'column',
            data: dataValues2
          },
        ]
        this.series = params
      }
      else{
          const params = [
          {
            name: 'Students',
            type: 'column',
            data: dataValues
          }
        ]
        this.series = params
      }
    }
  }
}
</script>
