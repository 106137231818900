<template>
  <div>
    <v-card>
      <v-card-title class="align-start">
        <span class="font-weight-semibold">{{title}}</span>
      </v-card-title>

      <v-card-text>
        <v-row justify="space-between">
          <!-- Attendance -->
          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiHand }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Attendance
              </p>
              <h3 class=" font-weight-semibold">
                {{  attendance }}
              </h3>
            </div>
          </v-col>

          <!-- Assignment Score -->

          <v-col
            cols="2"
            class="d-flex align-center"
            v-if="staff"
          >
            <v-avatar
              size="44"
              color="info"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCounter }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Assignment Score
              </p>
              <h3 class=" font-weight-semibold">
                {{  assignment }}
              </h3>
            </div>
          </v-col>

        <!-- Assignment completed -->

          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="info"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiPen }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                #Assignments
              </p>
              <h3 class=" font-weight-semibold">
                {{  assignmentsCompleted }}
              </h3>
            </div>
          </v-col>
          
          <!-- Quiz Score -->

          <v-col
            v-if='staff'
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="success"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCounter }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Quiz Score
              </p>
              <h3 class=" font-weight-semibold">
                {{  quiz }}
              </h3>
            </div>
          </v-col>

        <!-- Quiz Completed -->

          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="success"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiHelpBox }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                #Quizes
              </p>
              <h3 class=" font-weight-semibold">
                {{  quizesCompleted }}
              </h3>
            </div>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-3" v-if="staff">
      <v-card-title class="align-start">
        <span class="font-weight-semibold">
          Overall Course Stats (Part 2)
        </span>
      </v-card-title>

      <v-card-text>
        <v-row justify="space-between">
          <!-- Attendance -->
          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiAccountGroupOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                #Students
              </p>
              <h3 class=" font-weight-semibold">
                {{  numstudents }}
              </h3>
            </div>
          </v-col>

          <!-- Assignment Score -->

          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="info"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCounter }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Max Assignment Score
              </p>
              <h3 class=" font-weight-semibold">
                {{  maxAssignment }}
              </h3>
            </div>
          </v-col>

        <!-- Assignment completed -->

          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="info"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCounter }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Min Assignment Score
              </p>
              <h3 class=" font-weight-semibold">
                {{  minAssignment }}
              </h3>
            </div>
          </v-col>
          
          <!-- Quiz Score -->

          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="success"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCounter }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Max Quiz Score
              </p>
              <h3 class=" font-weight-semibold">
                {{  maxQuiz }}
              </h3>
            </div>
          </v-col>

        <!-- Quiz Completed -->

          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="success"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiHelpBox }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Min Quiz Score
              </p>
              <h3 class=" font-weight-semibold">
                {{  minQuiz }}
              </h3>
            </div>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-3" v-if="staff">
      <v-card-title class="align-start">
        <span class="font-weight-semibold">
          Overall Course Stats (Part 3)
        </span>
      </v-card-title>

      <v-card-text>
        <v-row justify="space-between">
          <!-- Num Males -->
          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiHumanMaleMale }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                #Male Students
              </p>
              <h3 class=" font-weight-semibold">
                {{  numMaleStudents }}
              </h3>
            </div>
          </v-col>

          <!-- Num Females -->

          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="primary"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiHumanFemaleFemale }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                #Female Students
              </p>
              <h3 class=" font-weight-semibold">
                {{  numFemaleStudents }}
              </h3>
            </div>
          </v-col>

        <!-- Course End Date -->

          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="error"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCalendarBlank }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Course End Date
              </p>
              <h3 class=" font-weight-semibold">
                {{  courseEnd | formatDate }}
              </h3>
            </div>
          </v-col>
          
        <!-- Course Days Left -->

          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="error"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCalendarBlank }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Course Days Left
              </p>
              <h3 class=" font-weight-semibold">
                {{  numDays - courseDaysCompleted }}
              </h3>
            </div>
          </v-col>

          <!-- Course Days Completed -->

          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="error"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiCalendarBlank }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Sessions Completed
              </p>
              <h3 class=" font-weight-semibold">
                {{  sessionsCompleted }}
              </h3>
            </div>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>


    <v-card class="mt-3" v-if="staff">
      <v-card-title class="align-start">
        <span class="font-weight-semibold">
          Overall Course Stats (Part 4)
        </span>
      </v-card-title>

      <v-card-text>
        <v-row justify="space-between">
          <!-- Num Modules -->
          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="error"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiBookOpenVariant }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Num Modules
              </p>
              <h3 class=" font-weight-semibold">
                {{  numModules }}
              </h3>
            </div>
          </v-col>

        <!-- Current Module  -->

          <v-col
            cols="2"
            class="d-flex align-center"
          >
            <v-avatar
              size="44"
              color="error"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ icons.mdiBookOpenVariant }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                Current Module
              </p>
              <h3 class=" font-weight-semibold">
                {{  currentModule }}
              </h3>
            </div>
          </v-col>

          <!-- Nothing -->
          <v-col
            cols="2"
            class="d-flex align-center"
          >
          </v-col>

        <!-- Nothing -->
          <v-col
            cols="2"
            class="d-flex align-center"
          >
          </v-col>

          <!-- Nothing -->
          <v-col
            cols="2"
            class="d-flex align-center"
          >
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

  </div>

</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, 
         mdiCurrencyUsd, 
         mdiTrendingUp, 
         mdiDotsVertical, 
         mdiHumanMaleMale,
         mdiHumanFemaleFemale,
         mdiCounter, 
         mdiCalendarBlank,
         mdiHand, 
         mdiPen, 
         mdiHelpBox, 
         mdiBookOpenVariant,
         mdiCheckCircleOutline,
         mdiSigma,
         mdiAccountGroupOutline } from '@mdi/js'

export default {
  mounted() {
    this.$store.dispatch('fetchAttendance', {studentId : this.staff ? 0 : this.$store.getters.user.id })
    this.$store.dispatch('fetchAssignmentStat', {studentId : this.staff ? 0 : this.$store.getters.user.id })
    this.$store.dispatch('fetchQuizStat', {studentId : this.staff ? 0 : this.$store.getters.user.id })
    this.$store.dispatch('fetchMoocStat', {studentId : this.staff ? 0 : this.$store.getters.user.id })
    this.$store.dispatch('fetchCourseSubjects')
    this.$store.dispatch('fetchCourses')

    /*** Only do this for staff. */
    if(this.staff){
      this.$store.dispatch('fetchStudentStat')
    }
  },
  data() {
    return {
      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiCounter,
        mdiCurrencyUsd,
        mdiHand,
        mdiPen,
        mdiHelpBox,
        mdiAccountGroupOutline,
        mdiHumanMaleMale,
        mdiHumanFemaleFemale,
        mdiCalendarBlank,
        mdiSigma,
        mdiBookOpenVariant,
        mdiCheckCircleOutline
      },
    }
  },
  filters: {
    daysDiff: function (value) {
      const today = new Date()
      return Math.round((value-today)/(1000*60*60*24));
    },
    daysDiff2: function (value) {
      const today = new Date()
      return Math.round((today-value)/(1000*60*60*24));
    },
    formatDate: function(date) {
      const day = date.toLocaleString('default', { day: '2-digit' });
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.toLocaleString('default', { year: 'numeric' });
      return day + '-' + month + '-' + year;
    }
  },
  computed:{
    courseEnd() {
      let courses = this.$store.getters.getCourses
      let endDate = new Date()

      for(let i = 0; i < courses.length; i++){
        let cDate = new Date(courses[i].endDate)

        if(cDate.getTime() > endDate.getTime()){
          endDate = cDate
        }
      }

      return endDate
    },
    courseStart() {
      let courseSubjects = this.$store.getters.getCourseSubjects
      let startDate = new Date()

      for(let i = 0; i < courseSubjects.length; i++){
        let cDate = new Date(courseSubjects[i].startDate)

        if(cDate.getTime() < startDate.getTime()){
          startDate = cDate
        }
      }

      return startDate
    },
    numModules () {
      let number = {}
      let courseSubjects = this.$store.getters.getCourseSubjects

      for(let i = 0; i < courseSubjects.length; i++){
        let subjectId = courseSubjects[i].subject.id
        number[subjectId] = subjectId
      }

      return Object.keys(number).length
    },
    numSesssions() {
      let number = 0
      let courseSubjects = this.$store.getters.getCourseSubjects

      for(let i = 0; i < courseSubjects.length; i++){
        let sessions = parseInt(courseSubjects[i].numberOfSessions)
        number = number + sessions
      }

      return number
    },
    numDays() {
      let number = 0
      let courseSubjects = this.$store.getters.getCourseSubjects

      for(let i = 0; i < courseSubjects.length; i++){
        let sDate = new Date(courseSubjects[i].startDate)
        let eDate = new Date(courseSubjects[i].endDate)
        number = number + Math.round((eDate-sDate)/(1000*60*60*24)) + 1
      }

      return number
    },
    staff(){
      return this.$store.getters.isStaff
    },
    title(){
      return this.staff ? 'Overall course stats (Part 1)' : 'My Course Stats (Overall)'
    },
    sessionsCompleted(){
      let courseSubjects = this.$store.getters.getCourseSubjects
      let number = 0
      let today = new Date()

      for(let i = 0; i < courseSubjects.length; i++){
        let sDate = new Date(courseSubjects[i].startDate)
        let eDate = new Date(courseSubjects[i].endDate)

        if(eDate < today){
          number = number + parseInt(courseSubjects[i].numberOfSessions)
        }
        else{
          if(sDate < today){
            number = number + Math.round((today-sDate)/(1000*60*60*24)) * 5
          }
        }
      }

      return number
    },
    currentModule () {
      let currentModule = ''
      let courseSubjects = this.$store.getters.getCourseSubjects
      let today = new Date()

      for(let i = 0; i < courseSubjects.length; i++){
        let sDate = new Date(courseSubjects[i].startDate)
        let eDate = new Date(courseSubjects[i].endDate)

        if(eDate >= today && today >= sDate){
          currentModule = courseSubjects[i].subject.subjectName
        }
      }

      return currentModule
    },
    courseDaysCompleted(){
      let courseSubjects = this.$store.getters.getCourseSubjects
      let number = 0
      let today = new Date()

      for(let i = 0; i < courseSubjects.length; i++){
        let sDate = new Date(courseSubjects[i].startDate)
        let eDate = new Date(courseSubjects[i].endDate)

        if(eDate < today){
          number = number + Math.round((eDate-sDate)/(1000*60*60*24))
        }
        else{
          if(sDate < today){
            number = number + Math.round((today-sDate)/(1000*60*60*24)) 
          }
        }
      }

      return number
    },
    numstudents(){
      if(!this.staff){
        return 0
      }

      try{
        return this.$store.getters.getStudentStat.length
      }
      catch(e){
        return 0
      }

    },
    numMaleStudents(){
      if(!this.staff){
        return 0
      }

      try{
        let number = 0
        let students = this.$store.getters.getStudentStat

        for(let i = 0; i < students.length; i++){

          if(students[i].isFemale == 0){
            number = number+1 
          }
        }
        return number
      }
      catch(e){
        return 0
      }

    },
    numFemaleStudents(){
      if(!this.staff){
        return 0
      }

      try{
        let number = 0
        let students = this.$store.getters.getStudentStat

        for(let i = 0; i < students.length; i++){

          if(students[i].isFemale == 1){
            number = number+1 
          }
        }
        return number
      }
      catch(e){
        return 0
      }

    },
    attendance(){
      let attendanceData = this.$store.getters.getAttendance
      
      if(attendanceData && attendanceData.length > 0){

        let present = 0, total = 0
        for(let i =0; i < attendanceData.length; i++){
          present += parseFloat(attendanceData[i].present)
          total += parseFloat(attendanceData[i].total)
        }

        return `${Math.round( 100 * ( present / total), 0)}%`;
      }

      return 'N/A'
    },
    assignment(){
      let assignmentData = this.$store.getters.getAssignmentStat
      
      if(assignmentData && assignmentData.length > 0){

        let avgScore = 0
        for(let i =0; i < assignmentData.length; i++){
          avgScore += parseFloat(assignmentData[i].avgScore)
        }

        return `${Math.round( avgScore / assignmentData.length , 0)}%`;
      }

      return 'N/A'
    },
    maxAssignment(){
      let assignmentData = this.$store.getters.getAssignmentStat
      
      if(assignmentData && assignmentData.length > 0){

        let maxScore = 0
        for(let i =0; i < assignmentData.length; i++){
          if(maxScore <  parseFloat(assignmentData[i].maxScore)){
            maxScore = parseFloat(assignmentData[i].maxScore)
          }
        }

        return `${Math.round( maxScore , 0)}%`;
      }

      return 'N/A'
    },
    minAssignment(){
      let assignmentData = this.$store.getters.getAssignmentStat
      
      if(assignmentData && assignmentData.length > 0){

        let minScore = 100
        for(let i =0; i < assignmentData.length; i++){
          if(minScore >  parseFloat(assignmentData[i].minScore)){
            minScore = parseFloat(assignmentData[i].minScore)
          }
        }

        return `${Math.round( minScore , 0)}%`;
      }

      return 'N/A'
    },
    assignmentsCompleted(){
      let assignmentData = this.$store.getters.getAssignmentStat
      
      if(assignmentData && assignmentData.length > 0){
        return assignmentData.length;
      }

      return 'N/A'
    },
    quiz(){
      let quizData = this.$store.getters.getQuizStat
      
      if(quizData && quizData.length > 0){

        let avgScore = 0
        for(let i =0; i < quizData.length; i++){
          avgScore += parseFloat(quizData[i].avgScore)
        }

        return `${Math.round( avgScore / quizData.length , 0)}`;
      }

      return 'N/A'
    },
    maxQuiz(){
      let quizData = this.$store.getters.getQuizStat
      
      if(quizData && quizData.length > 0){

        let maxScore = 0
        for(let i =0; i < quizData.length; i++){
          if(maxScore < parseFloat(quizData[i].maxScore) ) {
            maxScore =  parseFloat(quizData[i].maxScore)
          }
        }

        return `${Math.round( maxScore , 0)}`;
      }

      return 'N/A'
    },
    minQuiz(){
      let quizData = this.$store.getters.getQuizStat
      
      if(quizData && quizData.length > 0){

        let minScore = 0
        for(let i =0; i < quizData.length; i++){
          if(minScore < parseFloat(quizData[i].minScore) ) {
            minScore =  parseFloat(quizData[i].minScore)
          }
        }

        return `${Math.round( minScore , 0)}`;
      }

      return 'N/A'
    },
    quizesCompleted(){

      let quizData = this.$store.getters.getQuizStat

      if(quizData && quizData.length > 0){
        return quizData.length
      }

      return 'N/A'
    }
  }
}
</script>
